<template>
<div class="ds-container">
    <div class="ds-header" v-if="userHasPermission('LaudoMedConfigExam1')">
      <div class="ds-title">
        Exames laudáveis
      </div>
    </div>
    <div class="config-container" v-if="userHasPermission('LaudoMedConfigExam1')" ref="proceduresTable">
        <h3>Busque e adicione os procedimentos que poderão ser laudados e o tempo limite para laudar cada um.</h3>
        <div class="search-container">
            <Search class="icon-search"/>
            <b-form-input type="search" v-model="procedureQuery" @input="debounceSearchProcedures" placeholder="Buscar por exame ou procedimento"></b-form-input>
        </div>
        <v-procedures-table :procedures="procedures" @updateLaudableProcedures="getMedicalReportsData()"/>
    </div>

    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        first-number
        last-number
      />
    </div>


    <div class="ds-header" v-if="userHasPermission('LaudoMedConfigMed1')">
      <div class="ds-title">
        Médicos laudadores
      </div>
    </div>
    <div class="config-container" v-if="userHasPermission('LaudoMedConfigMed1')">
        <h3>Selecione os profissionais que realizam laudo dentro da clínica e quais exames eles podem laudar.</h3>
        <div class="search-container">
            <Search class="icon-search"/>
            <b-form-input type="search" v-model="professionalQuery" placeholder="Buscar por profissional"></b-form-input>
        </div>
        <v-professionals-table
            :professionals="professionalsFilter()"
            :laudableProcedures="laudableProcedures"
            @updateResponsibles="getMedicalReportsData()"
            :key="laudableProceduresUpdate" />
    </div>
</div>
</template>

<script>
import { userHasPermission } from '@/utils/localStorageManager'
import { debounce } from 'lodash';
import Search from '@/assets/icons/search.svg'
import * as ITEM from '@items/utils/constants'

import ProceduresTable from './components/ProceduresTable.vue'
import ProfessionalsTable from './components/ProfessionalsTable.vue'


export default {
    components: {
        Search,
        'v-procedures-table': ProceduresTable,
        'v-professionals-table': ProfessionalsTable,
    },

    data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      medicalReportRestrictedView: localStorage.getItem('medicalReportRestrictedView') ? localStorage.getItem('medicalReportRestrictedView') === 'true' : false,
      medicalReportAnyoneCanView: localStorage.getItem('medicalReportAnyoneCanView') ? localStorage.getItem('medicalReportAnyoneCanView') === 'true' : true,
      medicalReportsResponsibles: [],
      professionals: [],
      procedures: [],
      laudableProcedures: [],
      procedureQuery: '',
      professionalQuery: '',
      debounceSearchProcedures: debounce(this.getAllClinicProcedures, 500),
      laudableProceduresUpdate: 0,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
    }
  },

    mounted() {
         this.getMedicalReportsData()
    },

    methods: {
        userHasPermission,

        handleMedicalReportsView(changed){

            if(changed === 'medicalReportRestrictedView'){
                if(this.medicalReportRestrictedView)
                    this.medicalReportAnyoneCanView = false
                else
                    this.medicalReportAnyoneCanView = true
            }
            else if(changed === 'medicalReportAnyoneCanView'){
                if(this.medicalReportAnyoneCanView)
                    this.medicalReportRestrictedView = false
                else
                    this.medicalReportRestrictedView = true
            }

            localStorage.setItem('medicalReportRestrictedView', this.medicalReportRestrictedView)
            localStorage.setItem('medicalReportAnyoneCanView', this.medicalReportAnyoneCanView)

        },

        async getMedicalReportsData(){
            const isLoading = this.$loading.show()
            await this.getAllLaudableExams()
            await this.getAllClinicProcedures()
            await this.allMedicalReportsResponsibles()
            await this.getProfessionals()
            isLoading.hide()
        },

        professionalsFilter(){
            return this.professionals.filter((el) => {
                return el.name.toLowerCase().includes(this.professionalQuery.toLowerCase())
            } )
        },

        async allMedicalReportsResponsibles(){
            const response = await this.api.allMedicalReportsResponsibles(this.clinic.id)
            this.medicalReportsResponsibles = response.data
        },

        async getProfessionals(){
            const response = await this.api.getClinicDoctors(this.clinic.id)
            this.professionals = response.data.doctors.map(doctor => {
                const responsible = this.medicalReportsResponsibles.find(el => { return el.person_id === doctor.id })
                return {
                    'id': doctor.id,
                    'responsibleId': responsible ? responsible.id : null,
                    'name': doctor.name,
                    'active': responsible ? responsible.active : false,
                    'picture': doctor.picture,
                }
            })
        },

        async getAllLaudableExams(){
            await this.api.allLaudableProcedures(this.clinic.id)
            .then( res => {
                this.laudableProcedures = res.data
            })
        },

        async getAllClinicProcedures(){
            const types = ITEM.ITEMS_WITH_HEALTH_PLAN_PROPS
           await this.api.getAllClinicProcedures(this.clinic.id, false, this.procedureQuery, true, this.currentPage, types)
            .then(res => {
                this.currentPage = res.data.current_page
                this.perPage = res.data.per_page
                this.totalItems = res.data.total
                this.procedures = res.data.data.map((procedure, index) => {
                    const laudableExam = this.laudableProcedures.find(el => { return el.item_id === procedure.id })
                    return {
                        'id': procedure.id,
                        'name': procedure.name,
                        'active': laudableExam ? laudableExam.active : false,
                        'limit_time': laudableExam ? laudableExam.limit_time : ''
                    }
                })
            })
            .catch(err => { console.log('Err', err) })
        }
    },
    watch: {
        async currentPage() {
            await this.getAllClinicProcedures()
            this.$refs.proceduresTable.scrollIntoView({ behavior: 'smooth' })
        }
    }
}
</script>

<style lang="scss" scoped>
.ds-container {
    padding: 30px 10px;
    .ds-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 30px 0;
    }
    .ds-title {
      display: inline-flex;
      align-items: center;
      font-family: 'Red Hat Display';
      font-weight: 500;
      font-size: 18px;
    }

    .config-container {
        border-radius: 16px;
        border: 1px solid var(--neutral-300);
        padding: 25px;

        h3 {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            display: flex;
            align-items: center;
            color: #525C7A;
        }
        .search-container {
            display: flex;
            flex-direction: row;
            position: relative;
            margin: 10px 0 20px 0;
            .icon-search {
                stroke: var(--neutral-500);
                z-index: 2;
                width: 25px;
                position: absolute;
                margin-left: 15px;
                margin-top: 11px;
            }

            input {
                padding-left: 50px;
            }
        }

    }
    .view-options {
        display: flex;
        flex-direction: column;
        padding: 30px;
        border: 1px solid var(--neutral-300);
        border-radius: 8px;

        & > div {
            display: flex;
            flex-direction: row;
            justify-content: start;
            margin: 15px 0;

            label {
                margin-bottom: 0px !important;
                margin-right: 10px;
                font-weight: 600;
                font-size: 16px;
                line-height: 28px;
                color: #525C7A;
            }
        }
    }
}

</style>
