<template>
<div id="table-container">
<table class="procedures-table">
    <thead>
        <tr>
            <th class="align-start" >Procedimentos laudáveis</th>
            <th>Status</th>
            <th class="align-start" >Limite de dias para laudar</th>
            <th></th>
        </tr>
    </thead>

    <tbody>
        <tr v-for="( procedure, index ) in procedures" :key="index">
            <td class="procedure-name align-start" >{{ procedure.name }}</td>
            <td><toggle-button v-model="procedure.active" @change="updateLaudableProcedure(procedure)" :sync="true" :height="32" :color="{checked: '#00C773', unchecked: '#E1E5F4'}" /></td>
            <td class="date-picker-col">
                <b-form-input 
                    v-model="procedure.limit_time" aria-describedby="time" min="1"
                    :disabled="!procedure.active" type="number" debounce="300"
                    @change.native="updateLaudableProcedure(procedure)" placeholder="Dias para laudar">
                </b-form-input>
            </td>
            <td v-if="1 === 2"><Trash class="icon" v-bind:class="{ 'icon-disabled': !procedure.active }" /></td>
        </tr>
    </tbody>

</table>
</div>
</template>

<script>

import Trash from '@/assets/icons/trash.svg'
// import TimeDuration from '@/components/General/TimeDuration'

export default {
    components: {
        Trash, 
        // 'v-time-duration': TimeDuration,
    },
    props: {
        procedures: Array,
    },

    data() {
        return {
            clinic: JSON.parse(localStorage.getItem('clinic')),
        }
    },

    methods: {
        async updateLaudableProcedure(procedure){
            await this.api.updateLaudableProcedure(procedure.id, procedure.active, procedure.limit_time, this.clinic.id)
            this.$emit('updateLaudableProcedures', true)
        }   
    }
}
</script>

<style lang="scss" scoped>
    #table-container {
        width: 100%;
        max-height: 80vh;
        overflow: scroll;

        .procedures-table {
            width: 100%;
            overflow: scroll;
            table-layout: fixed;
            border-collapse: collapse;
            th {
                position: sticky;
                top: 0;
            }
            th, td {
                overflow-x: hidden;
                z-index: 10;
                min-width: 180px !important;
                padding: 25px  0 25px 10px !important;
                background-color: var(--neutral-000);
                border-bottom: 1px dotted var(--neutral-300);
            }

            tbody, thead {
                tr{
                    td, th {
                        width: 10%;
                    }
                    td, th:nth-child(1){
                        width: 60%;
                    }
                    td, th:nth-child(3){
                        width: 20%;
                    }
                }
            }

            .procedure-name {
                text-overflow: ellipsis;
            }

            .icon {
                cursor: pointer;
                width: 25px;
                stroke: rgba(251, 165, 157, 1);
            }

            .icon-disabled {
                cursor: default;
                stroke: rgb(211, 207, 207);
            }

            .align-start {
                text-align: start
            }
        }
    }

    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
      }

    ::-webkit-scrollbar-track {
        background: var(--neutral-000);
        border-radius: 0 0 8px 0;
      }

    ::-webkit-scrollbar-thumb {
        background-color: var(--neutral-300);
        border-radius: 100px;
        border: 3px solid var(--neutral-000);
      }

    ::-webkit-scrollbar-thumb:hover {
        cursor: pointer;
        background: var(--neutral-200);
      }
</style>