<template>
<div id="table-container">
<table class="professionals-table">
    <div v-for="(professional, index ) in professionals" :key="index" class="professional-container">
        <div class="header">
            <div class="doctor-container">
                <Avatar
                    class="mr-3"
                    size="40" 
                    :src="professional.picture"                
                />
                <h3>{{ professional.name }}</h3>
            </div>
            <div class="switch-container">
                <span v-if="professional.active">Laudador</span>
                <span v-else>Não laudador</span>
                <toggle-button v-model="professional.active" @change="updateMedicalReportsResponsible(professional)" :sync="true" :height="32" :color="{checked: '#00C773', unchecked: '#E1E5F4'}" />
            </div>
        </div>
        <hr v-if="professional.active" />
        <div class="procedures-container" v-if="professional.active && laudableProcedures.length > 0">
            <div class="search-container">
                <Search class="icon-search"/>
                <b-form-input type="search" v-model="procedureQuery" placeholder="Buscar por exame ou procedimento"></b-form-input>
            </div>
            <ul>
                <li class="procedures-header">
                    <span>Exames</span>
                    <span>Status</span>
                </li>
                <li v-for="( procedure, index ) in getProfessionalProcedures(professional)" :key="index">
                    <span>{{ procedure.name }}</span>
                    <toggle-button v-model="procedure.active" @change="updateLaudableProcedureResponsible(professional, procedure)" :height="32" :color="{checked: '#00C773', unchecked: '#E1E5F4'}" :key="procedure.active" />
                </li>
            </ul>
        </div>
        <span class="no-data" v-else-if="professional.active && laudableProcedures.length === 0">
            Você ainda não cadastrou nenhum exame como laudável. Defina quais podem ser laudados na tabela de "exame laudáveis".
        </span>
    </div>
</table>
</div>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {

    props: {
        professionals: Array,
        laudableProcedures: Array,
    },

    components: {
        Avatar: () => import('@/components/General/Avatar'),
        Search: () => import('@/assets/icons/search.svg')
    },

    data() {
        return {
            clinic: getCurrentClinic(),
            laudableProceduresResponsibles: [],
            procedureQuery: '',
        }
    },

    mounted() {
        this.allLaudableProceduresResponsibles()
    },

    methods: {
        async allLaudableProceduresResponsibles(){
            await this.api.allLaudableProceduresResponsibles(this.clinic.id)
            .then(res => {
                this.laudableProceduresResponsibles = res.data
            })
        },

        async updateLaudableProcedureResponsible(professional, procedure){
            await this.api.updateLaudableProcedureResponsible(professional.responsibleId, procedure.id, procedure.active)
        },

        async updateMedicalReportsResponsible(professional){
            await this.api.updateMedicalReportsResponsible(professional.id, professional.active, this.clinic.id)
            this.$emit('updateResponsibles', true);
        },

        getProfessionalProcedures(professional){
            const data = this.laudableProcedures.map(el => {
                const procedureResponsible =  this.laudableProceduresResponsibles.find(procedure => { 
                    return professional.responsibleId === procedure.responsible_id && el.id === procedure.laudable_procedure_id
                     })

                return {
                    'id': el.id,
                    'name': el.item.name,
                    'active': procedureResponsible ? procedureResponsible.active : false,
                }
            })
            if(this.procedureQuery === ''){
                return data
            } else {
                return data.filter((el) => {
                    return el.name.toLowerCase().includes(this.procedureQuery.toLowerCase())
                })
            }
        },
        async updateLaudableProcedure(procedure){
            if(procedure.limit_time){
                await this.api.updateLaudableProcedure(procedure.id, procedure.active, procedure.limit_time)
            }
        }   
    }
}
</script>

<style lang="scss" scoped>

 #table-container {
        width: 100%;
        max-height: 80vh;
        overflow: scroll;

        .professionals-table {
            width: 100%;
            overflow: scroll;
            table-layout: fixed;
            border-collapse: collapse;
        }

        .search-container {
            display: flex;
            flex-direction: row;
            position: relative;
            margin: 10px 0 20px 0;
            .icon-search {
                stroke: var(--neutral-500);
                z-index: 2;
                width: 25px;
                position: absolute;
                margin-left: 15px;
                margin-top: 11px;
            }

            input {
                padding-left: 50px;
            }
        }

        .professional-container {
            width: 100%;
            padding: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #C6CEEB;
            border-radius: 16px;
            margin-bottom: 20px;

            .header {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .doctor-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }

                h3 {
                    font-family: 'Red Hat Display';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 17px;
                    line-height: 134%;
                    display: flex;
                    align-items: center;
                    margin-bottom: 0 !important;
                    color: #525C7A;
                }

                .avatar {
                    width: 40px;
                    height: 40px;
                    margin-right: 16px;
                }

                .switch-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                    span {
                        font-family: 'Nunito Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 150%;
                        color: #0C1D59;
                        margin-right: 20px;
                    }
                }
            }

            hr {
                background: var(--neutral-200) !important;
                height: 0.5px;
                width: 100%;
                margin-top: 20px;
            }

            .procedures-container {
                width: 100%;
                margin-top: 10px;

                .procedures-header {
                    position: sticky;
                    top: 0;
                    z-index: 10;
                    background-color: #fff;
                    span {
                        font-family: 'Nunito Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 150%;
                        color: #525C7A;
                    }
                }

                ul {
                    width: 100%;
                    list-style: none;
                    padding-left: 0;
                    max-height: 60vh;
                    overflow: scroll;

                    li {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 25px 20px 25px 0;
                        border-bottom: 1px dashed #C6CEEB;
                    }
                }
            }
        }
    .no-data {
        width: 100%;
        padding: 50px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
 }
    
    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
      }

    ::-webkit-scrollbar-track {
        background: var(--neutral-000);
        border-radius: 0 0 8px 0;
      }

    ::-webkit-scrollbar-thumb {
        background-color: var(--neutral-300);
        border-radius: 100px;
        border: 3px solid var(--neutral-000);
      }

    ::-webkit-scrollbar-thumb:hover {
        cursor: pointer;
        background: var(--neutral-200);
      }
</style>